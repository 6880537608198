import logo from './logo.png';
import './App.css';
import {useEffect, useState} from "react";

function App() {

  const [daysUntilFriday, setDaysUntilFriday] = useState(0);
  const [quote, setQuote] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('https://mtle6uxmc4uxpgb44pxskqswky0biobh.lambda-url.eu-north-1.on.aws')
      .then(response => response.json())
      .then(data => {
        setDaysUntilFriday(data.daysUntilFriday);
        setQuote(data.quote);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  return (
    <div className="App">
      <header className="App-header">

        {loading ? <div/> : daysUntilFriday === 0 ?
          <div>
            <p>
              <img src={logo} className="App-logo" alt="logo"/>
            </p>
            <p>
              <a
                className="Spotify-link"
                href="https://open.spotify.com/artist/4KXp3xtaz1wWXnu5u34eVX"
                target="_blank"
                rel="noopener noreferrer"
              >
                Klicka, lyssna, njut p&aring; Spotify
              </a>
            </p>
            <p id="quote" className="Quote">
              "{quote}"
            </p>
          </div>
          : daysUntilFriday === 1 ?
            <div>
              <p>{daysUntilFriday} dag kvar...</p>
            </div>
            :
            <div>
              <p>{daysUntilFriday} dagar kvar...</p>
            </div>}
      </header>
    </div>
  );
}

export default App;
